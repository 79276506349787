import {Controller} from '@hotwired/stimulus';
import Sortable from 'sortablejs';
import {AxiosRequest} from '../helpers/axios_helper';

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this),
        });
    }

    end(event) {
        let id = event.item.dataset.id;
        let data = new FormData();
        data.append('position', event.newIndex + 1);
        new AxiosRequest(this.data.get('url').replace(':id', id), 'PATCH', data).sendHtml();
    }
}
